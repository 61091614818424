.text-block h1 {
    font-size: 3rem;
    font-weight: 800;
    line-height: 4rem;
}

.text-block h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2rem;
}

.text-block h3 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
}

.text-block p {
    font-size: 1rem;
    font-weight: 400;
}

.text-block a {
    font-size: 1rem;
    font-weight: 400;
    text-decoration: underline;
    opacity: 0.85;
}

.text-block ul { 
  font-size: 1rem;
  list-style-type: disc; 
  padding-left: 15px;
}
.text-block ol { 
  font-size: 1rem;
  list-style-type: decimal; 
  list-style-position: inside; 
}
.text-block ul ul, .text-block ol ul { 
  list-style-type: circle; 
}
.text-block ol ol, .text-block ul ol { 
  list-style-type: lower-latin;
}

.text-block-remove-span-color span {
    color: unset !important;
}
/* from tailwind https://tailwindcss.com/docs/font-size */
