.CheckboxContainer input {
    display: none;
}

.Checkbox .Indicator {
    opacity: 0
}

.CheckboxContainer input:checked ~ .Checkbox .Indicator {
    opacity: 1
}