@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --rsbs-backdrop-bg: none;
}

html,
body {
    padding: 0;
    margin: 0;
}

* {
    box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.disable-scrollbars::-webkit-scrollbar {
    display: none;
}

.disable-scrollbars {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* this is needed to modify the react-phone-number-input package */
.PhoneInputInput {
    background-color: inherit;
}
.PhoneInputInput:focus {
    outline: none;
}

button {
    all: unset;
}

.pageNavItem {
    transition: border-color 0.3s ease-in-out;
}
