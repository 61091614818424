/* CSS varibales for theme 3 */
:root {
    /* theme colors */
   --background: #ffffff;
   --primary:#ffffff;
   --secondary:#ffffff;
   --tertiary: #ffffff;
   --inverse: #ffffff;
   --border: #ffffff;
   --backdropFilter: unset;
   --uiBackground: #ffffff;
   --uiPrimary: #ffffff;
   --uiSecondary: #ffffff;
   --uiTertiary: #ffffff;
   --uiQuaternary: #ffffff;
   --uiQuinary: #ffffff;
   --uiInverse: #ffffff;
   --uiCritical: #ffffff;
   --uiSuccess: #ffffff;
   --uiBorder: #ffffff;
}

/* class name for theme 3 */

/* Primary */
.theme-primary-background {
    background: var(--primary);
}

.theme-primary-text {
    color: var(--primary);
}

.theme-primary-border {
    border: 1px solid var(--primary);
}

/* Secondary */
.theme-secondary-text {
    color: var(--secondary);
}

/* Tertiary */
.theme-tertiary-background {
    background: var(--tertiary);
    -webkit-backdrop-filter: var(--backdropFilter);
    backdrop-filter: var(--backdropFilter);
}

/* Inverse */
.theme-inverse-text {
    color: var(--inverse);
}

/* Border */
.theme-border {
    box-shadow: 0 0 0 1px var(--border);
}

/* Break */
.theme-break {
    border-color: var(--border);
}

/* Highlight */
.theme-highlight {
    /* success */
    box-shadow: 0 0 0 2px #0099ff;
}

/* text block style */
.text-color-h1,
.text-color-h2,
.text-color-h3 {
    color: var(--primary);
}
