@import "@radix-ui/colors/blackA.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/gray.css";

@import "@radix-ui/colors/mauveDark.css";
@import "@radix-ui/colors/grayDark.css";
.DropdownMenuContent,
.DropdownMenuSubContent {
    background-color: white;
    border-radius: 6px;
    /* padding: 5px; */
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
        0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
}
.DropdownMenuContent[data-side="top"],
.DropdownMenuSubContent[data-side="top"] {
    animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side="right"],
.DropdownMenuSubContent[data-side="right"] {
    animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side="bottom"],
.DropdownMenuSubContent[data-side="bottom"] {
    animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side="left"],
.DropdownMenuSubContent[data-side="left"] {
    animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
    font-size: 14px;
    line-height: 2.5rem;
    color: var(--gray11);
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    position: relative;
    padding-left: 25px;
    user-select: none;
    outline: none;
    min-width: 240px;
    cursor: pointer;
}
.AdminPageDropdownCheckboxItem {
    font-size: 14px;
    line-height: 2.5rem;
    color: var(--gray11);
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    position: relative;
    padding-left: 25px;
    user-select: none;
    outline: none;
    min-width: 25px;
    cursor: pointer;
}

.DropdownMenuSubTrigger[data-state="open"] {
    background-color: var(--gray4);
    color: var(--gray11);
}
.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.AdminPageDropdownCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
    color: var(--mauve8);
    pointer-events: none;
}
.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.AdminPageDropdownCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
    background-color: var(--gray9);
    color: var(--gray1);
}

.DropdownMenuLabel {
    padding-left: 25px;
    font-size: 12px;
    line-height: 25px;
    color: var(--mauve11);
}

.DropdownMenuSeparator {
    height: 1px;
    background-color: var(--gray6);
    margin: 5px;
}

.DropdownMenuItemIndicator {
    position: absolute;
    left: 0;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.DropdownMenuArrow {
    fill: white;
}

.IconButton {
    font-family: inherit;
    border-radius: 100%;
    height: 48px;
    width: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--gray11);
    box-shadow: 0 2px 10px var(--blackA7);
}
.IconButton:hover {
    background-color: var(--gray3);
}
.IconButton:focus {
    box-shadow: 0 0 0 2px black;
}

.RightSlot {
    margin-left: auto;
    padding-left: 20px;
    padding-right: 8px;
    color: var(--mauve11);
}
[data-highlighted] > .RightSlot {
    color: white;
}
[data-disabled] .RightSlot {
    color: var(--mauve8);
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
