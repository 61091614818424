/* TODO: move back to tailwind */
@import "@radix-ui/colors/blackA.css";

.SliderRoot {
    user-select: none;
    touch-action: none;
}
.SliderRoot[data-orientation="horizontal"] {
    height: 20px;
}
.SliderRoot[data-orientation="vertical"] {
    flex-direction: column;
    width: 20px;
    height: 100px;
}

.SliderTrack {
    flex-grow: 1;
}
.SliderTrack[data-orientation="horizontal"] {
    height: 3px;
}
.SliderTrack[data-orientation="vertical"] {
    width: 3px;
}

.SliderRange {
    height: 100%;
}

.SliderThumb {
    width: 20px;
    height: 20px;
    background-color: white;
    box-shadow: 0 2px 10px var(--blackA7);
    border-radius: 10px;
}
.SliderThumb:focus {
    outline: none;
    box-shadow: 0 0 0 5px var(--blackA8);
}
